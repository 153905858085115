import { render, staticRenderFns } from "./DetalheServidor.vue?vue&type=template&id=2199584b&scoped=true&"
import script from "./DetalheServidor.vue?vue&type=script&lang=js&"
export * from "./DetalheServidor.vue?vue&type=script&lang=js&"
import style0 from "./DetalheServidor.vue?vue&type=style&index=0&id=2199584b&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2199584b",
  null
  
)

export default component.exports